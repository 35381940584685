.root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-position: 50%;
    background-size: 100% 100%;
    background-image: url(../../assets/images/loginBackground.png);
}
