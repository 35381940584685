.MenuItem {
    height: 51px;
    border-bottom: solid #fff !important;
};
.MenuItem.Mui-selected {
    color: #3865DC;
    background-color: transparent !important;

  }
  
  