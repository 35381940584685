.container {
    display: flex;
    // min-width: 850px;
    height: 100%;
    .sider {
        width: 256px;
        flex-shrink: 0;
        .content {
            background-color: rgba(56, 101, 220, 0.1);
            min-height: 100%;
            // position: fixed;
            // top: 0;
            // left: 0;
            overflow-y: auto;
        }
    }
    .layout {
        width: 100%;
        min-height: 100%;
        background-color: #fff;
        overflow-x: hidden;
    }
}
